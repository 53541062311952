<script>

//import api from '@/services/secureHttps';
import WebsiteSearch from "@/components/nestedSearch/nested-search.vue";
import secureHttps from "../services/secureHttps";
import Modal from 'bootstrap/js/dist/modal';
import Spinner from "@/components/spinner";

export default {
    components: {
        WebsiteSearch,
        Spinner
    },

    props: {
        websiteId: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            pageId: null,
            isLoading: false
        };
    },

    methods: {
        handleOnPageSelected(pageInfoObj) {
            console.log(pageInfoObj);

            this.isLoading = true;
            secureHttps.checkScriptStatus(pageInfoObj.website.id, pageInfoObj.id)
                .then(r => {
                    console.log("isPageScriptInstaled", r.data);
                    var modalEl = null;
                    var modal = null;

                    if (r.data.isScriptInstalled === true) {
                        modalEl = document.getElementById("scriptInstalledModal");
                        modal = new Modal(modalEl);
                        modal.show();
                    } else {
                        modalEl = document.getElementById("scriptNotInstalledModal");
                        modal = new Modal(modalEl);
                        modal.show();
                    }

                }).finally(() => {
                    this.isLoading = false
                });
        }
    }

}
</script>
 
<template>
    <div>
        <div class="row">

            <!-- end col -->
            <div class="col">
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <p class="
                          text-uppercase
                          fw-bold
                          text-truncate
                          mb-0
                        ">
                            Check Page Script Status
                        </p>
                    </div>

                    <!-- end card header -->

                    <!-- card body -->
                    <div class="card-body">

                        <div class="row">
                            <div class="col">
                                <p>Check if script is installed on a webpage.</p>
                                <WebsiteSearch :websiteId="websiteId" @onPageSelected="handleOnPageSelected"
                                    :resultsMaxHeight="394" />
                                <div class="overlay justify-content-center align-middle" v-show="isLoading">
                                    <div class="centerSpinner">
                                        <Spinner :isLoading="isLoading" style=""></Spinner>
                                        <p class="text-center text-white">Checking page...</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-show="isLoading">
                            <div class="col">

                            </div>
                        </div>

                        <!-- end card body-->
                    </div>
                    <!-- end card -->
                </div>

                <!-- start script not installed modal-->
                <div class="modal fade" id="scriptNotInstalledModal" data-bs-backdrop="static" data-bs-keyboard="false"
                    tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-body text-center p-5">
                                <div class="mt-4">
                                    <h4 class="mb-3 text-danger">Script not installed</h4>
                                    <div class="hstack gap-2 justify-content-center"><button type="button"
                                            class="btn btn-light" data-bs-dismiss="modal">Close</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end script not inatalled modal-->
                </div>

                <!-- start script installed modal-->
                <div class="modal fade" id="scriptInstalledModal" data-bs-backdrop="static" data-bs-keyboard="false"
                    tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-body text-center p-5">
                                <div class="mt-4">
                                    <h4 class="mb-3 text-success">Script installed</h4>
                                    <div class="hstack gap-2 justify-content-center"><button type="button"
                                            class="btn btn-light" data-bs-dismiss="modal">Close</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end script installed modal -->
                </div>
                <!-- end col -->
            </div>
            <!-- end row-->
        </div>
    </div>
    <!-- end card -->
</template>

<style scoped>
.overlay {
    position: absolute;
    z-index: 1000;
    width: 90%;
    height: 90%;
    top: 13%;
    left: 30px;
    opacity: 0.5;
    filter: alpha(opacity=50);
    background-color: black;
}

.centerSpinner {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
</style>