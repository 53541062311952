<script>
function getChartColorsArray(colors) {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
      if (color) {
        color = color.replace(" ", "");
        return color;
      } else return newValue;
    } else {
      var val = value.split(',');
      if (val.length == 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import WebsiteSetup from "@/components/websiteSettings/websiteSetup.vue";
import { mapGetters } from 'vuex';

import api from '@/services/secureHttps';
import WebCrawlModal from '@/components/modals/webCrawlerInfoModal';
import GooglePromotion from '@/components/searchConsole/promotion';
import ClientScriptStatus from '@/components/clientScriptStatus';
import ClientScriptInstructions from '@/components/clientScript/installScriptInstructions';

export default {
  components: {
    Layout,
    PageHeader,
    WebCrawlModal,
    GooglePromotion,
    WebsiteSetup,
    ClientScriptStatus,
    ClientScriptInstructions
  },

  watch: {
    selectedWebisteId: {
      handler: async function () { 
        this.getSearchConsoleInfo();
        await this.getCrawlSummary();
      }
    }
  },

  async mounted() {
    if (this.selectedWebisteId) {
      this.getSearchConsoleInfo();
      await this.getCrawlSummary();
    }
  },

  computed: {
    ...mapGetters('context', [
      'selectedWebisteId',
      'selectedOrganisationId'
    ])
  },


  data() {
    return {
      title: "Dashboard",
      items: [{
        text: "Dashboards",
        href: "/",
      },
      {
        text: "Dashboard",
        active: true,
      },
      ],
      date: null,
      config: {
        mode: "range",
      },
      series: [44, 55, 41, 17, 15],
      chartOptions: {
        labels: ["Direct", "Social", "Email", "Other", "Referrals"],
        chart: {
          height: 333,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        stroke: {
          show: false,
        },
        dataLabels: {
          dropShadow: {
            enabled: false,
          },
        },
        colors: getChartColorsArray('["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]'),
      },
      crawlerStatus: {
        pagesCrawled: 0,
        crawlStarted: null,
        crawlEnded: null,
        crawlProgress: null
      },
      crawlInfoObj: {},
      hasIntegratedSearchConsole: true
    };
  },
  methods: {

    rightcolumn() {
      if (document.querySelector('.layout-rightside-col').classList.contains('d-none')) {
        document.querySelector('.layout-rightside-col').classList.remove('d-none')
      } else {
        document.querySelector('.layout-rightside-col').classList.add('d-none')
      }
    },

    crawlSite() {
      api.startCrawl(this.selectedWebisteId)
        .then(() => {
          this.poll({
            fn: this.getCrawlSummary,
            validate: this.validateCrawl,
            interval: 5000,
            maxAttempts: 100
          });
        })
        .catch(response => {
          if (response.status == 404) {
            console.log("no crawls started");
          }
        });
    },

    async getCrawlSummary() {
      var response = await api.getCrawlSummary(this.selectedWebisteId);

      this.crawlInfoObj = response.data;

      this.crawlerStatus = {};

      this.crawlerStatus.crawlEnded = new Date(response.data.crawlEnded);
      this.crawlerStatus.crawlStarted = new Date(response.data.crawlStarted);
      this.crawlerStatus.crawlProgress = response.data.crawlSummary.crawlProgress;
      this.crawlerStatus.pagesCrawled = response.data.crawlSummary.crawlStatus.pagesCrawled;
      return response;
    },

    validateCrawl(crawlResponse) {
      if (typeof crawlResponse === 'undefined') {
        return;
      }
      if (crawlResponse.data.crawlStatus === "complete") {
        return true;
      }
    },

    poll({ fn, validate, interval, maxAttempts }) {
      console.log('Start poll...');
      let attempts = 0;

      const executePoll = async (resolve, reject) => {
        console.log('- poll');
        const result = await fn();
        attempts++;

        if (validate(result)) {
          return resolve(result);
        } else if (maxAttempts && attempts === maxAttempts) {
          return reject(new Error('Exceeded max attempts'));
        } else {
          setTimeout(executePoll, interval, resolve, reject);
        }
      };

      return new Promise(executePoll);
    },

    formatDate(dateStr) {
      if (dateStr) {
        return new Date(dateStr).toLocaleString();
      }
    },

    getGreeting() {
      var today = new Date()
      var curHr = today.getHours()

      if (curHr < 12) {
        return 'Good morning!';
      } else if (curHr < 19) {
        return 'Good afternoon!';
      } else {
        return 'Good evening!';
      }
    },

    getSearchConsoleInfo() {
      api.getAppInfo(1, this.selectedOrganisationId, this.selectedWebisteId)
        .then(response => {
          this.hasIntegratedSearchConsole = response.data.isInstalled;
        });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col">
        <div class="h-100">
          <div class="row mb-3 pb-1">
            <div class="col-12">

              <!-- Web crawler info modal -->

              <div id="crawlerInfo" class="modal fade modal-dialog-scrollable" tabindex="-1"
                aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
                <WebCrawlModal :crawlInfoObj="crawlInfoObj"></WebCrawlModal>
              </div>



              <div class="d-flex align-items-lg-center flex-lg-row flex-column">
                <div class="flex-grow-1">
                  <h4 class="fs-16 mb-1">{{getGreeting()}}</h4>
                  <p class="text-muted mb-0">
                    Here's what's happening with your website today.
                  </p>
                </div>
              </div>
              <!-- end card header -->
            </div>
            <!--end col-->
          </div>
          <!--end row-->

          <div v-if="hasIntegratedSearchConsole === false" class="row">
            <div class="col">

              <GooglePromotion></GooglePromotion>
            </div>
            <!-- end col -->
          </div>
          <div v-if="isNaN(selectedWebisteId) === false" class="row">
            <div class="col-xl-6">
              <ClientScriptInstructions :websiteId="selectedWebisteId"></ClientScriptInstructions>
              <!-- card -->
              <div class="card card-animate bg-secondary">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 overflow-hidden">
                      <p class="
                          text-uppercase
                          fw-bold
                          text-white-50 text-truncate
                          mb-0
                        ">
                        Website Crawler
                      </p>
                    </div>

                  </div>
                  <div class="d-flex align-items-end justify-content-between mt-4">

                    <div>
                      <table class="table align-middle table-nowrap">
                        <thead class="table">
                          <tr>
                            <th scope="col">Pages Crawled</th>
                            <th scope="col">Date Started</th>
                            <th scope="col">Date Ended</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <!--end thead-->
                        <tbody>
                          <tr>
                            <td class="text-wrap">
                              <h4 class="fs-20 fw-bold ff-secondary text-white ">
                                {{ this.crawlerStatus.pagesCrawled }}

                              </h4>
                            </td>
                            <td class="text-wrap">
                              <h4 class="fs-20 fw-bold ff-secondary text-white">
                                {{ formatDate(crawlerStatus.crawlStarted) }}

                              </h4>
                            </td>
                            <td class="text-wrap">
                              <h4 class="fs-20 fw-bold ff-secondary text-white ">
                                {{ formatDate(crawlerStatus.crawlEnded) }}

                              </h4>
                            </td>
                            <td class="text-wrap">
                              <h4 class="fs-20 fw-bold ff-secondary text-white ">
                                {{ this.crawlerStatus.crawlProgress }}

                              </h4>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- Outline Buttons -->

                      <b-button variant="light" class="waves-effect" @click="crawlSite()">
                        <i class="ri-file-list-3-line align-middle"></i> Crawl my website
                      </b-button>
                    </div>
                    <div class="avatar-sm flex-shrink-0" data-bs-toggle="modal" data-bs-target="#crawlerInfo">
                      <span class="avatar-title bg-soft-light rounded fs-3">
                        <i class="bx bx-info-circle text-white"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- end card body -->


              </div>
              <!-- end card -->
            </div>
            <div class="col-xl-6">
              <ClientScriptStatus :websiteId="selectedWebisteId"></ClientScriptStatus>
            </div> 

          
            <!-- end col -->

          </div>
          <!-- end row-->

          <!--website settings row-->
          <div class="row" v-if="isNaN(selectedWebisteId) === false">
            <div class="col">
              <WebsiteSetup :orgId="selectedOrganisationId" :websiteId="selectedWebisteId"></WebsiteSetup>
            </div>
          </div>

        </div>
        <!-- end .h-100-->
      </div>
      <!-- end col -->



    </div>
  </Layout>
</template>