<script>

export default {


    props: {
        crawlInfoObj: {
            type: Object
        }
    },

    methods: {
        formatDate(dateStr) {
            return new Date(dateStr).toLocaleString();
        }
    }

}
</script>
 
<template>

    <div v-if="crawlInfoObj.crawlSummary" class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myModalLabel">Web Crawl Detail</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
            </div>
            <div class="modal-body">

                <h2 class="fs-15">
                    Page Metrics
                </h2>
                <div v-if="crawlInfoObj.crawlSummary" class="row mb-3">
                    <div class="col">
                        <table class="table align-middle table-nowrap">
                            <tbody>
                                <tr>
                                    <td class="no-wrap">
                                        Total links to external sites
                                    </td>
                                    <td class="text-wrap">
                                        {{ crawlInfoObj.crawlSummary.pageMetrics.linksExternal }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-wrap">
                                        Total links to other pages on this website
                                    </td>
                                    <td class="text-wrap">
                                        {{ crawlInfoObj.crawlSummary.pageMetrics.linksInternal }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-wrap">
                                        Total duplicate titles
                                    </td>
                                    <td class="text-wrap">
                                        {{ crawlInfoObj.crawlSummary.pageMetrics.duplicateTitle }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-wrap">
                                        Total duplicate descriptions
                                    </td>
                                    <td class="text-wrap">
                                        {{ crawlInfoObj.crawlSummary.pageMetrics.duplicateDescription }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-wrap">
                                        Total pages with same content
                                    </td>
                                    <td class="text-wrap">
                                        {{ crawlInfoObj.crawlSummary.pageMetrics.duplicateContent }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-wrap">
                                        Total broken links
                                    </td>
                                    <td class="text-wrap">
                                        {{ crawlInfoObj.crawlSummary.pageMetrics.brokenResources }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-wrap">
                                        Total non indexable pages
                                    </td>
                                    <td class="text-wrap">
                                        {{ crawlInfoObj.crawlSummary.pageMetrics.nonIndexable }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <h2 class="fs-15">
                    Common Checks
                </h2>
                <div v-if="crawlInfoObj.crawlSummary" class="row mb-3">
                    <div class="col">
                        <table class="table align-middle table-nowrap">
                            <tbody>
                                <tr>
                                    <td class="no-wrap">
                                        Found sitemap
                                    </td>
                                    <td class="text-wrap">
                                        {{ crawlInfoObj.crawlSummary.domainInfo.checks.sitemap }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-wrap">
                                        Found robots.txt
                                    </td>
                                    <td class="text-wrap">
                                        {{ crawlInfoObj.crawlSummary.domainInfo.checks.robotsTxt }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-wrap">
                                        Has directory browsing enabled
                                    </td>
                                    <td class="text-wrap">
                                        {{ crawlInfoObj.crawlSummary.domainInfo.checks.testDirectoryBrowsing }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-wrap">
                                        Does website redirect HTTP requests to HTTPS
                                    </td>
                                    <td class="text-wrap">
                                        {{ crawlInfoObj.crawlSummary.domainInfo.checks.testHttpsRedirect }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-wrap">
                                        Main domain
                                    </td>
                                    <td class="text-wrap">
                                        {{ crawlInfoObj.crawlSummary.domainInfo.mainDomain }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <h2 class="fs-15">
                    SSL Information
                </h2>
                <div v-if="crawlInfoObj.crawlSummary" class="row mb-3">
                    <div class="col">
                        <table class="table align-middle table-nowrap">
                            <tbody>
                                <tr>
                                    <td class="no-wrap">
                                        Is certifificate valid
                                    </td>
                                    <td class="text-wrap">
                                        {{ crawlInfoObj.crawlSummary.domainInfo.sslInfo.validCertificate }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-wrap">
                                        Expiration date
                                    </td>
                                    <td class="text-wrap">
                                        {{ crawlInfoObj.crawlSummary.domainInfo.sslInfo.certificateExpirationDate }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-wrap">
                                        Certificate issuer
                                    </td>
                                    <td class="text-wrap">
                                        {{ crawlInfoObj.crawlSummary.domainInfo.sslInfo.certificateIssuer }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-wrap">
                                        Certificate subject
                                    </td>
                                    <td class="text-wrap">
                                        {{ crawlInfoObj.crawlSummary.domainInfo.sslInfo.certificateSubject }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-wrap">
                                        Certificate version
                                    </td>
                                    <td class="text-wrap">
                                        {{ crawlInfoObj.crawlSummary.domainInfo.sslInfo.certificateVersion }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="no-wrap">
                                        Certificate hash
                                    </td>
                                    <td class="text-wrap">
                                        {{ crawlInfoObj.crawlSummary.domainInfo.sslInfo.certificateHash }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
            </div>

        </div><!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->


    <!-- Default Modals -->
    <div v-else class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="myModalLabel">Web Crawl Detail</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                </div>
                <div class="modal-body">
                    <p>It doesn't look like we have crawled your site. Press start 'Crawl my website' button and we'll show you the details
                        we
                        found about your website.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                </div>

            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->

</template>


