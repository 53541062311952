<script>

import api from '@/services/secureHttps';

export default {

    props: {
        orgId: {
            type: Number,
            required: true
        },
        websiteId: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            website: {
                siteName: null,
                alternativeName: null,
                websiteLogoUrl: null,
                socialMediaLinks: []
            },
            article: {
                authorType: null,
                articleType: null,
                authorProfileUrl: null
            },
            websiteSearch: {
                searchUrl: null,
                searchParameterName: null
            },
            isLoading: false
        };
    },

    watch: {
        websiteId: function () {
            this.loadWebsiteSettings();
        }
    },

    methods: {
        loadWebsiteSettings() {
            this.isLoading = true
            api.getWebsiteDefaultSettings(this.websiteId).then((response) => {
                console.log('got settings', response.data.metaDataDefaultSettings);
                var defaultSettings = response.data.metaDataDefaultSettings

                if (typeof defaultSettings !== 'undefined' && defaultSettings !== null) {
                    this.website = defaultSettings.website;
                    this.article = defaultSettings.article;
                    this.websiteSearch = defaultSettings.websiteSearch;
                }

                this.website.socialMediaLinks.push("");
            })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        submitSettings() {
            this.isLoading = true;
            var settingsObj = {
                website: this.website,
                article: this.article,
                websiteSearch: this.websiteSearch
            };

            console.log(settingsObj);

            api.upsertWebsiteDefaultSettings(this.websiteId, settingsObj).then(() => {
                console.log('succeeeding');
            })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false
                });
        }
    }

}
</script>
 
<template>
    <div>
        <b-card>
            <h1>Structured Data Settings</h1>
            <p>Setup these setting to enable more automation for creating schema on your website</p>

            <b-tabs card>

                <!-- <b-tab title="Website" active>
                    <b-card-body class="text-center">
                        <div class=" text-start mb-1">
                            <p>Fill in the form below to indicate how you want your website represented.
                            </p>
                        </div>
                        <form>
                            <div class=" text-start mb-1">
                                <span>The name of your site</span>
                            </div>
                            <div class="input-group mb-3">
                                <span class="input-group-text">Site name</span>
                                <input type="text" class="form-control" id="sitename" v-model="website.siteName">
                            </div>

                            <div class=" text-start mb-1">
                                <span>Use a concise, commonly-recognized name for your site (for example, "Google" instead
                                    of "Google, Inc").
                                    While there's no limit to how long a site name can be, on some devices long site names
                                    may be truncated.
                                    If you have an alternate name that's commonly recognized, you can use the alternateName
                                    property to specify that name (for example, an acronym).</span>
                            </div>
                            <div class="input-group mb-3">
                                <span class="input-group-text">Friendly site name</span>
                                <input type="text" class="form-control" id="friendlyName" v-model="website.alternativeName">
                            </div>
                            <div class=" text-start mb-1">
                                <span>Provide logo for your website/organization. Image must be at least 112px x
                                    112px.</span>
                            </div>
                            <div class="input-group mb-3">
                                <span class="input-group-text">Website logo</span>
                                <input type="text" class="form-control" placeholder="e.g https://www.example.com/logo.png"
                                    v-model="website.websiteLogoUrl">
                            </div>

                        </form>
                    </b-card-body>
                </b-tab>
                <b-tab title="Search box">
                    <form>
                        <div class=" text-start mb-1">
                            <p>If your site has a search function. You can tell google the url of the search page and
                                users can use your search enging from
                                Google search results page.
                            </p>
                            <p>Benefits of embedding your website feature in google search results</p>
                            <ul>
                                <li>Users can find exactly what they need, increasing the chances that they convert rather
                                    than bouncing in frustration.</li>
                                <li>Your website can take up more real estate in the SERPs, decreasing the chances that your
                                    customers click on a different link below yours</li>
                                <li>Increase your conversion rate and organic search traffic. More people are finding what
                                    they want by honing keywords in the SERPs.</li>
                            </ul>
                        </div>
                        <div class="input-group mb-3">
                            <span class="input-group-text">Search engine URL</span>
                            <input type="text" class="form-control" id="searchUrl" v-model="websiteSearch.searchUrl">
                        </div>

                        <div class=" text-start mb-1">
                            <span>To be able to enable this feature we need to know the parameter in the search URL your
                                website uses to understand what it is the user is searching for.
                                For example if the URL for search is https://www.example.com/products/search?q=headphones.
                                This url indicates the user is searching for headphones
                                and the paramerter name is q. Therefore you would add q in the search parameter name input
                                field below.
                            </span>
                        </div>
                        <div class="input-group mb-3">
                            <span class="input-group-text">Search parameter name</span>
                            <input type="text" class="form-control" v-model="websiteSearch.searchParameterName">
                        </div>
                    </form>
                </b-tab> -->
                <b-tab title="Articles">
                    <div class=" text-start mb-1">
                        <h3>Publisher</h3>
                        <span>Who do you want to represent your articles?
                        </span>
                        <p>Here are some example to help you decide.</p>
                        <ul>
                            <li>I am a freelance copywriteer, my name is John Doe and my website is johnDoe.com. Select
                                Organization</li>
                            <li>I want to give credit to the author of the article. Select person</li>
                            <li>I work for an online ecommerce company and I want to build the company brand. Select
                                Organisation</li>
                        </ul>
                    </div>
                    <div class="mb-3">
                        <div class="form-check mb-2">
                            <input class="form-check-input" type="radio" name="authorType" id="authorTypeOrganization"
                                v-model="article.authorType" value="Organization">
                            <label class="form-check-label" for="flexRadioDefault1">
                                Organization
                            </label>
                        </div>

                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="authorType" id="authorTypePerson"
                                value="Person" v-model="article.authorType">
                            <label class="form-check-label" for="flexRadioDefault2">
                                Person
                            </label>
                        </div>
                    </div>
                    <hr>
                    <h3>Content Type</h3>
                    <div class=" text-start">
                        <p>Complete this form if you are only have one writer on your site.
                        </p>
                    </div>

                    <div class=" text-start mb-1">
                        <span>Select the type of article you generaly write
                        </span>
                    </div>
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="articleType" id="articleTypeNewsArticle"
                                v-model="article.articleType" value="NewsArticle">
                            <label class="form-check-label" for="flexRadioDefault2">
                                News Article / current events
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="articleType" id="articleTypeBlogPost"
                                v-model="article.articleType" value="BlogPost">
                            <label class="form-check-label" for="flexRadioDefault2">
                                Blog Post
                            </label>
                        </div>
                        <div class="form-check mb-2">
                            <input class="form-check-input" type="radio" name="articleType" id="articleTypeArticle"
                                v-model="article.articleType" value="Article">
                            <label class="form-check-label" for="flexRadioDefault1">
                                Other type of article
                            </label>
                        </div>

                    </div>

                    <div class=" text-start mb-1">
                        <span>Enter a URL where you can find more information of the author. This allows the search engine to understand the author better. This could be a linked in url or profile page on your website
                        </span>
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text">Author profile URL</span>
                        <input type="text" class="form-control" v-model="article.authorProfileUrl">
                    </div>

                </b-tab>
                <!-- <b-tab title="Social Media">
                    <b-card-text>Enter your social network links which you would like associated to your
                        website/organization.
                        For example, Twitter, Linked in, Facebook etc</b-card-text>

                    <div class="input-group mb-3" v-for="(item, index) in website.socialMediaLinks" :key="index">
                        <span class="input-group-text">Social Network URL</span>
                        <input type="text" class="form-control" v-model="website.socialMediaLinks[index]">
                    </div>

                    <p>...button to add more</p>
                </b-tab> -->

            </b-tabs>
            <div class="row justify-content-end">
                <div class="col-2 d-flex align-items-end justify-content-end">
                    <div v-if="isLoading">
                        <div class="spinner-border text-primary ms-2" :style="`width: ${width}rem; height: ${height}rem;`"
                            role="status">
                        </div>
                    </div>
                    <button class="btn btn-primary" @click="submitSettings">Save changes</button>
                </div>
            </div>
        </b-card>
    </div>
    <!-- end card -->
</template>
