<script>

//import secureHttps from "../../services/secureHttps";
//import Modal from '../bootstrap/js/dist/modal';
import Spinner from "@/components/spinner";
import api from '@/services/secureHttps';

export default {
    components: {
        Spinner
    },

    props: {
        websiteId: {
            type: Number,
            required: true
        }
    },

    watch: {
        websiteId: {
            // eslint-disable-next-line no-unused-vars
            handler: function (x, y) {
                if (typeof x !== 'undefined') {
                    this.getScript();
                }
            },
            default: null
        }
    },

    mounted() {
        if (typeof this.websiteId !== 'undefined') {
            this.getScript();
        }
    },

    data() {
        return {
            pageId: null,
            isLoading: false,
            scriptText: "",
            failedToGetScript: false
        };
    },

    methods: {
        getScript() {
            this.isLoading = true;
            api.getWebsiteInfo(this.websiteId)
                .then(response => {
                    console.log("website info response:", response.data);
                    this.scriptText = response.data.managerScript;
                }).catch(() => {
                    this.failedToGetScript = true;
                }).finally(() => {
                    this.isLoading = false;
                });
        }
    }

}
</script>
 
<template>
    <div>
        <div class="row">

            <!-- end col -->
            <div class="col">
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <p class="
                          text-uppercase
                          fw-bold
                          text-truncate
                          mb-0
                        ">
                            Install Script Instructions
                        </p>
                    </div>

                    <!-- end card header -->

                    <!-- card body -->
                    <div class="card-body">

                        <div class="row">
                            <div class="col">
                                <p>Copy and paste this into the page you would like to make your page better.</p>
                                <pre>{{ scriptText }}</pre>

                                <p v-if="failedToGetScript">
                                    Failed to get script.
                                </p>
                            </div>
                        </div>

                        <div class="row" v-show="isLoading">
                            <div class="col">
                                <Spinner :isLoading="isLoading"></Spinner>
                            </div>
                        </div>

                        <!-- end card body-->
                    </div>
                    <!-- end card -->
                </div>

                <!-- end col -->
            </div>
            <!-- end row-->
        </div>
    </div>
    <!-- end card -->
</template>

<style scoped>
.overlay {
    position: absolute;
    z-index: 1000;
    width: 90%;
    height: 90%;
    top: 13%;
    left: 30px;
    opacity: 0.5;
    filter: alpha(opacity=50);
    background-color: black;
}

.centerSpinner {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

pre {
    background: #303030b7;
    color: #f1f1f1;
    padding: 10px 16px;
    border-top: 7px solid #8083FF;
    counter-reset: line;
}

pre span {
    display: block;
    line-height: 1.5rem;
}

pre span:before {
    counter-increment: line;
    content: counter(line);
    display: inline-block;
    border-right: 1px solid #ddd;
    padding: 0 .5em;
    margin-right: .5em;
    color: #888;
    width: 30px;
}
</style>