<script>

export default {


    data() {
        return {
        };
    },

    methods: {
        signinGoogle() {
            this.$router.push({ name: 'apps-store' });
        },

        createGoogleSearchConsoleAccount() {
            window.open('https://search.google.com/search-console/about', '_blank');
        }
    }

}
</script>
 
<template>
    <div class="row">
        <dvi class="col">
            <div class="card card-animate bg-success">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <div class="d-flex align-items-center">

                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="
                                        text-uppercase
                                        fw-bold
                                        text-white-50
                                        mb-0
                                        ">
                                        Step 1: Integrate with Google Search Console
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <p class="fs-16 fw-semibold ff-secondary text-white mb-4">
                                        This will allow us to provide you with information on how well the changes you make are performing.
                                    </p>
                                    <p class="fs-16 fw-semibold ff-secondary text-white mb-4">If you do not have a search console account. Don't worry it's free, click the button below to start.</p>
                                    <button type="button" class="btn btn-light btn-sm right"
                                        @click="createGoogleSearchConsoleAccount()">
                                        <i class="ri-file-list-3-line align-middle"></i> Create search console account
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col text-center bottom">
                            <img src="@/assets/images/analytics/googleAanalytics.jpg" width="300" class="" />
                        </div>
                    </div>
                    <!-- end card body -->
                </div>
                <!-- end card -->
            </div>
        </dvi>
    </div>
    <div class="row">
        <div class="col">



            <!-- card -->
            <div class="card card-animate bg-primary">
                <div class="card-body">
                    <div class="row">
                        <div class="col-4">&nbsp;</div>
                        <div class="col justify-content-end">

                                    <p class="
                                        text-uppercase
                                        fw-bold
                                        text-white-50
                                        mb-0">
                                        Step 2: Connect Google Search Console
                                    </p>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-4 text-center">
                            <img src="@/assets/images/analytics/googleSeo.jpg" width="300" class="" />
                        </div>
                        <div class="col">
                            <div class="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 class="fs-16 fw-semibold ff-secondary text-white mb-4">
                                        This will allow us to track your website performance and increase your SEO
                                        capability.
                                    </h4>
                                    <button type="button" class="btn btn-light btn-sm" @click="signinGoogle()">
                                        <i class="ri-file-list-3-line align-middle"></i> Sign in to google search
                                        console
                                    </button>
                                </div>
                            </div>

                        </div>


                    </div>
                    <!-- end card body -->
                </div>
                <!-- end card -->
            </div>

        </div>
    </div>

</template>


<style scoped>
.span-multiple-rows {
  grid-row: span 2; /* Adjust the number of rows as needed */
}
</style> 